.parallax-menu {
	min-height: 100vh;
}

.home-carousel {
	position: relative;
}

.btn-container {
	display: flex !important;
	justify-content: center;
}

.modal-btn-sm-portrait {
	position: absolute;
	top: 140px;
	font-variant-caps: all-small-caps;
	font-weight: bold;
	opacity: 0.9;

	/*left: 20%;*/
	width: 250px;
	color: white;

	border-style: solid;
	border-width: 0.05em;
	border-radius: 0.5em;
	border-color: #580000;

	margin-bottom: 50px;
	box-shadow: 0.5px 0.5px 2px 0.5px #580000;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer;
}

.modal-btn-sm-portrait:hover,
.modal-btn-sm-portrait:focus,
.modal-btn-sm-portrait:active {
	color: #bbb;
	opacity: 0.7;
	border-width: 0.05em;
	box-shadow: 0px 0px 0px 0px black;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: none;
}

.carousel-control-prev,
.carousel-control-next {
	cursor: default !important;
}

.carousel-indicators {
	display: none !important;
}

.carousel-title-1 {
}

.carousel-p-1 {
	color: white !important;
}

.carousel-title-3 {
}

.carousel-p-3 {
	color: black !important;
}

.carousel-img {
	object-fit: cover;

	width: 100%;
	height: 550px;
}

.title {
	color: #778492;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	color: #778492;
}

.title:hover {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	color: #778492;
}

.place {
	font-weight: normal;
	color: #666666;
}

.place a {
	font-weight: normal;
	color: #666666;
}

.place a:hover {
	color: #666666;
}

.time-frame {
	font-weight: normal;
}

.resume-row {
	margin-left: 0px;
	margin-right: 0px;
}

.job-title {
}

.solution-header {
	margin-top: 20px;
}

.resume-item {
	max-width: 100%;
	padding-left: 0px;
	padding-right: 0px;
	display: inline-flex;
}

.experience-content {
	font-family: "Lato", arial, sans-serif;
}

.experience-content-x {
	padding-bottom: 20px;
}

.blog-intro {
	margin-bottom: 30px;
	text-decoration: none !important;
	color: #434343;
}

.blog-list-x {
	margin-bottom: 30px;
}

.book-ads-row {
	justify-content: center;
}

.book-ads {
	padding: 10px;
}

.book-hdr {
	margin-top: 30px;
}

.iframe-container1 {
	height: 800px !important;
}

.btc-mobile {
	min-height: 1900px;
}

.btc-section {
	min-height: 800px;
}

.btc-section-mobile {
	min-height: 1900px;
}
