.logo-icon-sm {
	border: 0px;
}

.ios-icon-sm {
	margin-top: 2em;
	margin-bottom: 2em;
	width: 50%;
}

.insta-icon-sm {
	margin-top: 2em;
	margin-bottom: 2em;
	width: 30%;
	border-color: transparent;
}

.logo-icon-md {
	border: 0px;
	margin-top: 4em;
	margin-bottom: 4em;
	width: 30%;
}

.ios-icon-md {
	margin-top: 4em;
	margin-bottom: 4em;
	width: 40%;
}

.logo-icon-lg {
	border: 0px;
	margin-top: 6em;
	margin-bottom: 6em;
	width: 20%;
}

.ios-icon-lg {
	margin-top: 6em;
	margin-bottom: 6em;
	width: 30%;
}

.my-typist {
}
