.App {
  margin: 0px;
  padding: 0px;
}

.container {
  max-width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.view {
  font-family: "Lato", arial, sans-serif;
  color: #434343;
  background: #dae3e7;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

.app-view {
  max-width: 90%;
  padding-top: 3em;
  display: flex;
}

.app-view-xs {
  max-width: 93%;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
}

.main-view {
  max-width: 65%;
  margin: 0px;
  padding-right: 20px;
}

.main-view-research {
  max-width: 100%;
  margin: 0px;
  padding-right: 20px;
}

.main-view-ml {
  max-width: 65%;
  margin: 0px;
  padding-right: 20px;
}

.main-view-xs {
  max-width: 100%;
  margin: 0px;
  padding-right: 0px;
}

.aside-view {
  max-width: 35%;
  margin: 0px;
}

.aside-view-xs {
  max-width: 100%;
  margin: 0px;
}

.aside-view-ml {
  max-width: 35%;
  margin: 0px;
}

.App-logo-sm {
  margin-top: 35px;
  margin-bottom: 20px;
  max-width: 50vmin;
  border-radius: 150px;
}

.App-logo-md {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 30px;
  max-width: 35vmin;
  border-radius: 150px;
}

.App-logo-lg {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 15vmin;
  border-radius: 150px;
}

.section-heading {
  background: #fff;
  padding: 30px;
}

.section-heading h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
}

.section-heading p {
  font-size: 18px;
}

.section-heading h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #778492;
}

.section-heading-xs {
  background: #fff;
  padding: 15px;
  text-align: justify;
}

.section-heading-xs h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
}

.section-heading-xs p {
  font-size: 18px;
}

.section-heading-xs h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #778492;
}

.skills {
  margin-bottom: 0px;
  color: #ccd1d6;
  line-height: 1 !important;
  text-align: left !important;
}

.skill-progress {
  margin-bottom: 25px;
}

.skills-xs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  color: #ccd1d6;
  line-height: 1 !important;
  text-align: left !important;
}

.skill-level {
  padding-bottom: 5px;
}

.skills-title-xs {
  padding-left: 15px;
}

.skill-level-xs {
  margin-right: 15px;
  padding-bottom: 5px;
}

.info-icon {
  width: 1em;
  margin-right: 5px;
  padding-bottom: 2px;
}

.language-context {
  margin-bottom: 0px;
  color: #999;
}

.review-star {
  width: 2em;
  margin-bottom: 25px;
}

.brand-lg {
  width: 100%;
}

.brand-lg-vert1 {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.brand-lg-vert2 {
  width: 100%;
  margin-top: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.brand-lg-contact-vert1 {
  width: 85%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.brand-lg-contact-vert2 {
  width: 100%;
  margin-top: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.profile-img-col {
  position: relative;
  justify-content: center;
}

.profile-img-col-lg {
  max-width: 170px !important;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .profile-name-col-lg {
    position: relative;
    margin: 0px;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .profile-name-col-lg {
    position: relative;
    margin: 0px;
    margin-left: 50px;
  }
}

@media (min-width: 1300px) and (max-width: 2500px) {
  .profile-name-col-lg {
    position: relative;
    margin: 0px;
    margin-left: 60px;
  }
}

@media (min-width: 2501px) {
  .profile-name-col-lg {
    position: relative;
    margin: 0px;
    margin-left: 100px;
  }
}

.profile-contact-col-lg {
  max-width: 312px !important;
  position: relative;
}

.profile-desc-col-lg {
  padding: 0px;
}

.engineer-name {
  color: #49515a;
  font-size: 38px;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.engineer-name-xs {
  color: #49515a;
  font-size: 30px;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.job-description {
  color: #778492;
  font-family: "Lato", arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 30px;
}
.nav-item {
  width: 28px;
  margin-right: 0px !important;
  padding-right: 0px;
}

.social-media-icons {
  margin-bottom: 35px;
}

.social-media-icons-lg {
  margin-bottom: 35px;
  margin-left: 1px;
  padding-top: 20px;
}

.social-icon {
  width: 2em;
}

.contact-icon {
  width: 2em;
}

.link-icon {
  width: 1em;
  margin-right: 10px;
}

.contact-info {
  padding-top: 5px;
  padding-left: 30px;
}

.contact-info p {
  font-size: 16px;
}

.iframe-class1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 350px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 106%;
    width: 100%;
  }

  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 5000%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 115980%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 120%;
    width: 100%;
  }
}

@media (min-width: 351px) and (max-width: 440px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 85%;
    width: 100%;
  }

  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 3700%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 85500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 85%;
    width: 100%;
  }
}

@media (min-width: 441px) and (max-width: 575px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 60%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 2750%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 55500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 45%;
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 50%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 1950%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 35500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 35%;
    width: 100%;
  }
}

@media (min-width: 676px) and (max-width: 790px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 44%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 1300%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 25500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 30%;
    width: 100%;
  }
}

@media (min-width: 791px) and (max-width: 899px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 37%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 1250%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 15500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 25%;
    width: 100%;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 58%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 1150%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 10500%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 40%;
    width: 100%;
  }
}

@media (min-width: 1101px) and (max-width: 1355px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 46%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 870%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 8000%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 30%;
    width: 100%;
  }
}

@media (min-width: 1356px) and (max-width: 1650px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 37%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 680%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 4000%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 20%;
    width: 100%;
  }
}

@media (min-width: 1651px) and (max-width: 2050px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 31%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 550%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 2000%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 15%;
    width: 100%;
  }
}

@media (min-width: 2051px) {
  .iframe-container1 {
    position: relative;
    overflow: hidden;
    padding-top: 28%;
    width: 100%;
  }
  .iframe-containerBtc {
    position: relative;
    overflow: hidden;
    padding-top: 415%;
    width: 100%;
  }

  .iframe-containerBtc2 {
    position: relative;
    overflow: hidden;
    padding-top: 1000%;
    width: 100%;
  }

  .iframe-container2 {
    position: relative;
    overflow: hidden;
    padding-top: 15%;
    width: 100%;
  }
}

.gh-activity-feed {
  padding-top: 8%;
}

.gh-activity-feed div {
}

.iframe-class2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.iframe-class3 {
  top: 0;
  left: 0;
  border: 0;
}

.feature-content {
  position: relative;
  margin-bottom: 60px;
  text-align: center !important;
}

.feature-content-xs {
  position: relative;
  margin-bottom: 60px;
  text-align: justify !important;
}

.feature-content-title {
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  color: #778492 !important;
  font-size: 20px !important;
}

.feature-summary {
  margin-bottom: 30px;
  color: #778492;
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", arial, sans-serif;
}

.project-image {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  margin-bottom: 30px;
}

.project-image:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}

.has-ribbon {
  position: relative;
  display: inline-block;
}

.ribbon {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 110px;
  height: 110px;
  overflow: hidden;
}

.ribbon-text {
  font-family: "Montserrat", sans-serif;
  position: relative;
  left: -8px;
  top: 18px;
  width: 158px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #479fc8;
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -moz-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  -o-transform: rotate(45deg) translate3d(0, 0, 0);
}

.ribbon-text:before {
  margin-left: -60px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  bottom: -5px;
  border-top: 5px solid #276582;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.ribbon-text:after {
  margin-left: 50px;
  box-sizing: border-box;
  content: "";
  position: absolute;
  bottom: -4px;
  border-top: 4px solid #276582;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.feature-description {
  text-align: left !important;
  margin-bottom: 30px;
  font-family: "Lato", arial, sans-serif;
  color: #434343;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feature-description-xs {
  text-align: justify !important;
  margin-bottom: 30px;
  font-family: "Lato", arial, sans-serif;
  color: #434343;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.profile-btn-col {
  margin-bottom: 20px;
  border-left: 1px;
  border-right: 1px;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 10px;
  border-color: #49ac43;
  background-color: #49ac43;
  padding-top: 11px;
  padding-left: 18px;
  height: 60px;
  width: 250px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Montserrat", arial, sans-serif;
  font-size: 25px;
  cursor: pointer;
}

.profile-btn-col:hover,
.profile-btn-col:active,
.profile-btn-col:focus {
  border-color: #28a745;
  background-color: #28a745;
}

.like-btn-col {
  margin-right: 0px;
  margin-bottom: 20px;
  border-left: 1px;
  border-right: 1px;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 10px;
  border-color: #49ac43;
  background-color: #49ac43;
  padding-top: 11px;
  padding-left: 5px;
  height: 55px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Montserrat", arial, sans-serif;
  font-size: 20px;
  cursor: pointer;

  max-width: 15em;

  -ms-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.like-btn-col-xs {
  margin-right: 0px;
  margin-bottom: 20px;
  border-left: 1px;
  border-right: 1px;
  border-top: 1px;
  border-bottom: 1px;
  border-radius: 10px;
  border-color: #49ac43;
  background-color: #49ac43;
  padding-top: 11px;
  padding-left: 0px;
  height: 45px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Montserrat", arial, sans-serif;
  cursor: pointer;
  max-width: 15em;
  transform: translate(0%, 0%);
}

.like-btn-col-resume {
  padding-top: 30px !important;
}

.like-btn-col-resume-xs {
  padding-top: 30px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.verbose-resume {
  border-color: #17a2b8 !important;
  background-color: #17a2b8 !important;
}

.verbose-resume:hover {
  border-color: #4390af !important;
  background-color: #4390af !important;
}

.one-pager {
}

.like-btn-col:hover,
.like-btn-col:active,
.like-btn-col:focus {
  border-color: #28a745;
  background-color: #28a745;
}

.divider {
  margin-top: 60px;
  margin-bottom: 60px;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.featured-item-xs {
  flex-direction: column;
}

.find-more {
  margin-bottom: 50px;
}

.featured-titles {
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  color: #778492 !important;
  text-decoration: none !important;
}

.featured-titles-xs {
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  color: #778492 !important;
  text-decoration: none !important;
}

.feat-container-xs {
  align-items: center;
  justify-content: center;
}

.p-desc {
  margin-bottom: 0.5rem !important;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 16px !important;
  text-decoration: none !important;
  color: #434343;
}

.featured-img-xs {
  display: flex;
  justify-content: center;
}

.project-icon-qat {
  margin-top: 25%;
  width: 100%;
}

.project-icon-med {
  margin-top: 0%;
  width: 100%;
}

.project-icon-med-spec {
  padding-top: 25%;
  width: 100%;
}

.project-icon-med-visa {
  margin-top: 10%;
  margin-left: 15px;
  width: 80%;
}

.project-icon-med-nasa {
  margin-top: 25%;
  margin-left: 10px;
  width: 100%;
}

.project-icon-qat-xs {
  margin-top: 10px;
  margin-bottom: 40px;
  width: 40%;
  height: 40%;
}

.project-icon-med-spec-xs {
  margin-top: 50px;
  margin-bottom: 30px;
  width: 40%;
  height: 40%;
}

.project-icon-ML-xs {
  margin-top: 0px;
  margin-bottom: 40px;
  width: 40%;
  height: 40%;
}

.project-icon-med-visa-xs {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 30%;
  height: 30%;
}

.project-icon-med-nasa-xs {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 40%;
  height: 40%;
}

.project-icon-med-xs {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 40%;
  height: 40%;
}

.feat-link {
  text-decoration: none !important;
}

.btn-icon {
  width: 12%;
  margin-right: 10px;
}

.like-btn-icon {
  width: 10%;
  margin-right: 18px;
  margin-left: 20px;
}

.like-btn-icon-resume {
  width: 10%;
  margin-right: 8px;
  margin-left: 8px;
}

.back-project-cta {
  position: relative;
  align-items: center;
}

.github-cta {
  position: relative;
  align-items: center;
}

.like-col {
  justify-content: center;
  margin-right: 0px;
}

.like-col-xs {
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;
}

.nav-link {
  display: block;
  padding: 0.5rem 0.5rem;
}

.app-navi-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.navi-link-logout-sm {
  font-weight: bold;
}

.navi-link-sellers-sm {
  font-weight: bold;
}

.navi-link-signin-sm {
  font-weight: bold;
}

.navi-link-logout-md {
  font-weight: bold;
}

.navi-link-sellers-md {
  font-weight: bold;
}

.navi-link-signin-md {
  font-weight: bold;
}

.navi-link-logout-lg {
  font-weight: bold;
}

.navi-link-sellers-lg {
  margin-left: 10px;
  font-weight: bold;
}

.navi-link-signin-lg {
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
