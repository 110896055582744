.chrome {
	padding-bottom: 20px;
}

.invite-title-xs {
	margin-top: 10px;
	margin-left: 0px;

	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}

.invite-title {
	margin-top: 10px;
	margin-left: 10px;

	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}

.invite-user-container-sm {
	margin: 10px;
}

.invite-user-container-xs {
	margin: 0px;
}

.invite-user-container-md {
	margin-bottom: 10px !important;
	background-color: #f9f9f9;
	margin: 1em 1.5em 2em;
	padding: 5px;
	box-shadow: 1px 1px 8px #bbb;
}

.user-details-xs {
	padding-top: 20px;
	padding-bottom: 30px;
	padding-left: 0px;
	padding-right: 0px;
}

.user-details-md {
	padding-top: 20px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
}
