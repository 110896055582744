.research-heading {
	background: #fff;
	padding: 10px;
}

.feat-abstract {
	text-decoration: none !important;
	font-family: "Lato", arial, sans-serif !important;
	color: #434343 !important;
	font-size: 18px !important;
}

.feat-abstract:hover,
.feat-abstract:focus,
.feat-abstract:active {
	text-decoration: none !important;
	font-family: "Lato", arial, sans-serif !important;
	color: #434343 !important;
	font-size: 18px !important;
}
