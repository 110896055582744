@media (min-width: 319px) and (max-width: 348px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 240px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 240px !important;
    }
}

@media (min-width: 349px) and (max-width: 395px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 270px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 270px !important;
    }
}

@media (min-width: 396px) and (max-width: 430px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 290px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 290px !important;
    }
}

@media (min-width: 431px) and (max-width: 490px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 310px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 310px !important;
    }
}

@media (min-width: 491px) and (max-width: 531px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 345px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 345px !important;
    }
}

@media (min-width: 532px) and (max-width: 575px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 375px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 375px !important;
    }
}

@media (min-width: 576px) and (max-width: 695px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 450px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 450px !important;
    }
}

@media (min-width: 696px) and (max-width: 790px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 500px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 500px !important;
    }
}

@media (min-width: 791px) and (max-width: 899px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 550px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 550px !important;
    }
}

/* NOTE: LEFT OFF HERE */

@media (min-width: 900px) and (max-width: 1000px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 400px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 400px !important;
    }
}

@media (min-width: 1001px) and (max-width: 1100px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 450px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 450px !important;
    }
}

@media (min-width: 1101px) and (max-width: 1365px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 500px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 500px !important;
    }
}

@media (min-width: 1366px) and (max-width: 1650px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 600px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 600px !important;
    }
}

@media (min-width: 1651px) and (max-width: 2090px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 750px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 750px !important;
    }
}

@media (min-width: 2091px) {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__right__1tzAF {
        right: 850px !important;
    }

    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v.coverflow__left__PhMc6 {
        left: 850px !important;
    }
}

/* NOTE: Needed for Arrow on Cover flow */
@media all {
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v span,
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v:before,
    #coverflow__arrow1__2X2XF .coverflow__arrow__IDi7v:after {
        background: black !important;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        position: absolute;
        top: calc(50% - (2px / 2));
    }
    .coverflow__coverflow__53z9A {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #dae3e7;
    }
}
