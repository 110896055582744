html,
body {
	height: 100%;
	font-family: "Lato", sans-serif;
}

body {
	margin: 0;
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-size: 18px;
	color: #333;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

select.formcontrol,
textarea.formcontrol,
input.form-control {
	font-size: 18px;
}

input[type="file"] {
	width: 100%;
	position: relative;
}
